import React from "react"
import { Link } from "gatsby"
import Image from "./image"

const CardNodo = props => (
  <div>
    <h2 className="text-center text-blue-500 font-bold text-xl mt-12">
      {props.nomeNodo}
    </h2>
    <div className={`${props.className} flex flex-col md:flex-row p-2 `}>
      <Image
        className="w-1/2 sm:w-1/4 rounded-lg mx-auto mt-3 sm:mt-0"
        filename={props.filename}
        alt={props.nomeNodo}
      ></Image>
      <div className="flex-1 m-5">
        <p className="text-justify">{props.description}</p>
      </div>
    </div>
  </div>
)

export default CardNodo
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import CardNodo from "../components/cardNodo"
import BlueSpan from "../components/blueSpan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"

const nodi = [
  {
    title: "Nodo Piano",
    filename: "nodi-marinari-piano.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "Viene usato per unire due corde di uguale diametro Resiste soltanto se sottoposto ad una trazione continua, se invece e sottoposto a strappi, spesso si allenta e si scioglie.",
  },
  {
    title: "Nodo Piano Ganciato",
    filename: "nodi-marinari-piano-ganciato.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      " L'utilità del ganciamento è data dalla facilità con cui si scioglie, tirando il doppino, si usa quando si teme di avere la necessità di sciogliere il nodo con grande rapidità.",
  },
  {
    title: "Nodo Bandiera",
    filename: "nodi-marinari-bandiera.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "Si usa per unire due corde di diverso spessore e che resistano a strappi o a variazioni di tensione, per legare le bandiere (di cui prende il nome)e per costruire le reti.",
  },
  {
    title: "Nodo Bandiera Doppio",
    filename: "nodi-marinari-bandiera-doppio.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "E’ il più affidabili fra questi nodi, grazie ai due colli di uno dei due cavi risulta difficile l’allentamento o lo sfilamento.",
  },
  {
    title: "Nodo Bandiera Ganciato",
    filename: "nodi-marinari-bandiera-ganciato.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "Si usa quando si suppone dover sciogliere rapidamente il nodo.",
  },
  {
    title: "Nodo Vaccaio",
    filename: "nodi-marinari-vaccaio.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "Simile al nodo piano, serve per unire due funi dello stesso diametro, ma offre maggiore sicurezza perché non si rovescia.",
  },
  {
    title: "Nodo Inglese",
    filename: "nodi-marinari-inglese.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Inglese Doppio",
    filename: "nodi-marinari-inglese-doppio.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      " Per unire due corde di uguale spessore, sulle estremità parallele e orientate in senso contrario di due cavi, si formano due nodi  che stretti tra di loro danno origine a una buona giunzione molto usata dai pescatori.",
  },
  {
    title: "Nodo del Chirurgo",
    filename: "nodi-marinari-del-chirurgo.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "e per il nodo inglese si usa per unire due corde, ma a differenza del primo offre una maggiore tenuta e viene usato principalmente dagli alpinisti.",
  },
  {
    title: "Nodo di Rosetta",
    filename: "nodi-marinari-di-rosetta.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "è un tipo particolare di nodo usato in chirurgia per serrare i fili di sutura.  Il più utilizzato è il nodo dritto che può essere semplice se il filo viene intrecciato una volta, oppure doppio quando il filo viene intrecciato due volte per evitare che il nodo possa allentarsi prima di essere fissato. ",
  },
  {
    title: "Nodo Due Gasse",
    filename: "nodi-marinari-due-gasse.jpg",
    link: "nodi-marinari-di-giunzione",
    description:
      "Particolare nodo di giunzione che utilizza 2 gasse d’amante semplici",
  },
]

const DiGiunzione = () => (
  <Layout>
    <SEO title=" I nodi marinari di Giunzione" />
    <h1 className="text-center text-blue-500 font-bold text-3xl mt-12">
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />{" "}
      I nodi marinari di Giunzione{" "}
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />
    </h1>
    <div className="container mx-auto">
      <div className="font-serif shadow p-5 text-justify italic text-xl mt-5">
        Si definiscono <BlueSpan>nodi marinari di giunzione</BlueSpan> tutti i
        nodi che servono per unire due cavi, anche di diverso diametro, soltanto
        formando un nodo ad una delle estremità di ciascuno, in modo che possono
        essere sciolte rapidamente.<br/> Esistono diverse tipologie di nodi di giunzione tra
        cui:
        <br />
        {nodi.map(nodo => (
          <CardNodo
            filename={nodo.filename}
            nomeNodo={nodo.title}
            description={nodo.description}
          ></CardNodo>
        ))}
      </div>
      <Card className="mt-5"></Card>
    </div>
  </Layout>
)

export default DiGiunzione

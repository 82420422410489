import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"

const Card = props => (
  <div
    className={`${props.className} bg-blue-100 flex flex-col md:flex-row shadow rounded-lg p-2 `}
  >
    <Image
      className="flex-auto rounded-lg"
      filename="quadri-nodi-marinari.jpg"
    ></Image>
    <div className="flex-1 m-5">
      <h2 className="text-blue text-2xl font-bold">
        Offerta Quadri Nodi Marinari
      </h2>
      <p className="italic">fatti a mano</p>
      <br />
      <p className="text-justify">
        Bellissimo set da collezione di quadri con nodi marinari 
        realizzati a mano e di pregevole fattura. <br /> Ottimo regalo per
        collezionisti ed appassionati
      </p>
      <br/>
      <a href="www.amazon.it" className="bg-blue-500 hover:bg-blue-400 text-white font-bold rounded-full p-2">
        Prezzo lancio: <span className="underline">99€</span>
      </a>
      <p></p>
    </div>
  </div>
)

export default Card